import React, { useEffect, useRef, useState } from "react";
import Peer, { DataConnection, MediaConnection } from "skyway-js";

const DemoMedia: React.FC = () => {
  const [peerId, setPeerId] = useState<string>("");
  const [
    tmpDataConnection,
    setTmpDataConnection,
  ] = useState<DataConnection | null>(null);
  const [
    tmpMediaConnection,
    setTmpMediaConnection,
  ] = useState<MediaConnection | null>(null);
  const canvasRef: any = useRef(null);
  const remoteVideoRef: any = useRef(null);
  const peerIdArea: any = useRef(null);
  const peer: any = new Peer({
    key: process.env.REACT_APP_SKYWAY_KEY
      ? process.env.REACT_APP_SKYWAY_KEY
      : "",
  });

  const getContext = (): CanvasRenderingContext2D => {
    const canvas: any = canvasRef.current;
    return canvas.getContext("2d");
  };

  const step = () => {
    const ctx: CanvasRenderingContext2D = getContext();
    ctx.fillRect(0, 0, 800, 450);
    ctx.arc(100, 100, 50, (0 * Math.PI) / 180, (360 * Math.PI) / 180, false);
    ctx.fillStyle = "rgba(255,0,0,0.8)";
    ctx.fill();
    ctx.lineWidth = 8;
    ctx.stroke();
    ctx.save();
    requestAnimationFrame(step);
  };

  useEffect(() => {
    // canvasに〇を描く
    step();
    // p2p-media接続
    initP2p();
  }, []);

  const initP2p = () => {
    // p2p-media接続
    peer.on("open", () => {
      console.warn("peer open");
      setPeerId(peer.id);
    });

    peer.on("call", (mediaConnection: any) => {
      const localStream = canvasRef.current.captureStream(1);
      setTmpMediaConnection(mediaConnection);
      mediaConnection.answer(localStream);

      mediaConnection.on("stream", async (stream: any) => {
        if (remoteVideoRef && remoteVideoRef.current) {
          remoteVideoRef.current.srcObject = stream;
          remoteVideoRef.current.muted = true;
          remoteVideoRef.current.play();
        }
      });

      mediaConnection.on("close", () => {
        if (remoteVideoRef && remoteVideoRef.current) {
          remoteVideoRef.current.srcObject = null;
        }
      });
    });

    peer.on("connection", (dataConnection: any) => {
      dataConnection.once("open", async () => {
        console.warn(`=== DataConnection has been opened ===\n`);
        console.warn("remoteVideoRef.currnet", remoteVideoRef.currnet);
        setTmpDataConnection(dataConnection);
      });

      dataConnection.once("close", () => {
        console.warn(`=== DataConnection has been closed ===\n`);
      });
    });
  };

  const copy = () => {
    peerIdArea.current.select();
    document.execCommand("Copy");
  };

  const sendClick = (e: any) => {
    const rect = e.target.getBoundingClientRect();
    const mouseX = e.clientX - Math.floor(rect.left);
    const mouseY = e.clientY - Math.floor(rect.top);

    const data = { type: { attribute: "click", x: mouseX, y: mouseY } };
    console.warn("sendClick", data);
    if (tmpDataConnection) tmpDataConnection.send(data);
  };

  const closeP2p = () => {
    if (tmpDataConnection) {
      tmpDataConnection.close(true);
    }
    if (tmpMediaConnection) {
      tmpMediaConnection.close(true);
      remoteVideoRef.current.srcObject = null;
    }
  };

  return (
    <div>
      <h1>skywayを接続して、メディア接続を確認するデモ</h1>
      <ul>
        <li>〇skywayでpeerを定義する</li>
        <li>〇peerIDをコピー可能なinput要素に書き出す</li>
        <li>〇p2p-mediaの受信機能を追加する</li>
        <li>〇p2p-dataの受信機能を追加する</li>
        <li>〇送られてきた映像をvideo要素に表示する</li>
        <li>
          〇video要素ないをクリックした場合、クリック位置をp2p-dataで送信する
        </li>
      </ul>
      <p>
        peerId: <input ref={peerIdArea} value={peerId} />
        <button onClick={copy}>コピー</button>
        <button onClick={closeP2p}>切断</button>
      </p>
      <canvas width="800px" height="450px" ref={canvasRef}></canvas>
      <video
        style={{ border: "solid 1px" }}
        ref={remoteVideoRef}
        width="800px"
        height="450px"
        muted={true}
        onMouseDown={sendClick}
      ></video>
    </div>
  );
};

export default DemoMedia;
