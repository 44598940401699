export const getScreenRequest = async () => {
  const url = `${process.env.REACT_APP_SERVER_DOMAIN}/rura/localserver/android/screen`;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  try {
    const res = await fetch(url, {
      method: "GET",
      headers,
    });
    return [await res.status, await res.json()];
  } catch (e) {
    throw await e;
  }
};
