import React, { useEffect, useRef, useState } from "react";
import Peer, { DataConnection, MediaConnection } from "skyway-js";

const Demo: React.FC = () => {
  const [peerId, setPeerId] = useState<string>("");
  const [
    tmpDataConnection,
    setTmpDataConnection,
  ] = useState<DataConnection | null>(null);
  const [clickPosition, setClickPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [scaleX, setScaleX] = useState(1);
  const remoteImgRef: any = useRef(null);
  const peerIdArea: any = useRef(null);
  const peer: any = new Peer({
    key: process.env.REACT_APP_SKYWAY_KEY
      ? process.env.REACT_APP_SKYWAY_KEY
      : "",
  });

  useEffect(() => {
    // p2p-media接続
    initP2p();
  }, []);

  const initP2p = () => {
    // p2p-media接続
    peer.on("open", () => {
      console.warn("peer open");
      setPeerId(peer.id);
    });

    peer.on("connection", (dataConnection: any) => {
      dataConnection.once("open", async () => {
        console.warn(`=== DataConnection has been opened ===\n`);
        setTmpDataConnection(dataConnection);
        //
      });

      dataConnection.on("close", () => {
        console.warn(`=== DataConnection has been closed ===\n`);
      });

      dataConnection.on("data", async (data: any) => {
        if (data.img) {
          console.warn("data", data.tablet.width, data.tablet.height);
          remoteImgRef.current.src = data.img;
          remoteImgRef.current.width = data.tablet.width;
          remoteImgRef.current.height = data.tablet.height;
        }
      });
    });
  };

  const copy = () => {
    peerIdArea.current.select();
    document.execCommand("Copy");
  };

  const closeP2p = () => {
    if (tmpDataConnection) {
      tmpDataConnection.close(true);
    }
  };

  const sendHome = (e: any) => {
    const data = { type: { attribute: "key", key: "KEYCODE_HOME" } };
    console.warn("sendHome", data);
    if (tmpDataConnection) tmpDataConnection.send(data);
  };

  const calculateDistance = (
    x1: number,
    y1: number,
    x2: number,
    y2: number
  ) => {
    return Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));
  };

  const onMouseUp = (e: any) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const mouseX = e.clientX - Math.floor(rect.left);
    const mouseY = e.clientY - Math.floor(rect.top);
    if (clickPosition) {
      console.warn(
        "calculateDistance",
        calculateDistance(mouseX, mouseY, clickPosition.x, clickPosition.y)
      );
      if (
        calculateDistance(mouseX, mouseY, clickPosition.x, clickPosition.y) > 50
      ) {
        // スクロールとして認識
        console.log("scroll");
        const data = {
          type: {
            attribute: "scroll",
            x: clickPosition.x,
            y: clickPosition.y,
            to_x: mouseX,
            to_y: mouseY,
          },
        };
        if (tmpDataConnection) tmpDataConnection.send(data);
      } else {
        console.log("click");
        // クリックとして認識
        const data = { type: { attribute: "click", x: mouseX, y: mouseY } };

        if (tmpDataConnection) tmpDataConnection.send(data);
      }
      setClickPosition(null);
    }
  };

  const onMouseDown = (e: any) => {
    const rect = e.target.getBoundingClientRect();
    const mouseX = e.clientX - Math.floor(rect.left);
    const mouseY = e.clientY - Math.floor(rect.top);
    setClickPosition({ x: mouseX, y: mouseY });
  };

  return (
    <div>
      <h1>skywayを接続して、メディア接続を確認するデモ</h1>
      <ul>
        <li>〇skywayでpeerを定義する</li>
        <li>〇peerIDをコピー可能なinput要素に書き出す</li>
        <li>〇p2p-mediaの受信機能を追加する</li>
        <li>〇p2p-dataの受信機能を追加する</li>
        <li>〇送られてきたbase64テキストをimg要素に表示する</li>
        <li>
          〇img要素ないをクリックした場合、クリック位置をp2p-dataで送信する
        </li>
      </ul>
      <p>
        peerId: <input ref={peerIdArea} value={peerId} />
        <button onClick={copy}>コピー</button>
        <button onClick={closeP2p}>切断</button>
      </p>
      <p>
        ボタン
        <button onClick={sendHome}>ホーム</button>
        <button
          onClick={() => {
            setScaleX(scaleX + 0.1);
          }}
        >
          拡大
        </button>
        <button onClick={() => setScaleX(scaleX > 0.8 ? scaleX - 0.1 : scaleX)}>
          縮小
        </button>
      </p>
      <div
        style={{
          height: "1920px",
          width: "1200px",
          transform: `scale(${scaleX} ,${scaleX})`,
        }}
      >
        <div
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          style={{ width: "100%", height: "100%" }}
        >
          <img
            style={{ border: "solid 1px", pointerEvents: "none" }}
            ref={remoteImgRef}
            width="800px"
            height="450px"
          />
        </div>
      </div>
    </div>
  );
};

export default Demo;
