import React, { useEffect, useRef, useState } from "react";
import Peer, { DataConnection } from "skyway-js";
import { useParams } from "react-router-dom";
import { clickRequest, scrollRequest } from "../api/clickHook";
import { getScreenRequest } from "../api/screenHook";
import { pressKeyRequest } from "../api/pressKeyHook";

const Main: React.FC = () => {
  const [peerId, setPeerId] = useState<string | null>(null);
  const [connectPeerId, setConnectPeerId] = useState<string>("");
  const [one, setOne] = useState<boolean>(false);
  const [
    tmpDataConnection,
    setTmpDataConnection,
  ] = useState<DataConnection | null>(null);
  const imgRef: any = useRef(null);
  const remoteVideoRef: any = useRef(null);
  const S = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const randomStr = Array.from(Array(6))
    .map(() => S[Math.floor(Math.random() * S.length)])
    .join("");

  // const search = useLocation().search;
  // const query = new URLSearchParams(search);
  const { queryPeeId, querySkywayKey } = useParams();
  const peer: any = new Peer(`${randomStr}_remote`, {
    key: querySkywayKey
      ? querySkywayKey
      : process.env.REACT_APP_SKYWAY_KEY
      ? process.env.REACT_APP_SKYWAY_KEY
      : "",
  });
  const randRange = (min: number, max: number) =>
    Math.floor(Math.random() * (max - min + 1) + min);

  const base64ToBlob = (base64: string, contentType: string) => {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: contentType });
}

  const convertBase64PngToJpeg = async (base64Png: string) => {
  // Base64文字列からプレフィックスを除去
  const base64Data = base64Png.replace(/^data:image\/png;base64,/, '');
  // PNG Blobに変換
    const pngBlob = base64ToBlob(base64Data, 'image/png');
  return pngBlob;
}

  let tabletImage: any = null;

  const step = (dataConnection: any = null, count: number) => {
    const result = getScreenRequest();
    result
      .then(async (res) => {
        // console.log("getScreenRequest", res);
        if (res[0] === 200) {
          const base64Png = res[1].data.img;
          const blobImage = await convertBase64PngToJpeg(base64Png);
          if (!blobImage) return;
          imgRef.current.src = blobImage;
          const width = Number(res[1].data.tablet.width);
          const height = Number(res[1].data.tablet.height);
          tabletImage = { img: blobImage, tablet: { width, height } };
          if (dataConnection) {
            // console.log("send data", tabletImage);
            dataConnection.send(tabletImage);
          }
          // console.log(`tablet width:${width} / height:${height}`);
          imgRef.current.width = width;
          imgRef.current.height = height;
        } else if (res[0] === 500) {
          console.error("500 adb がつながっていません", count);
          count += 1;
          if (count > 5) {
            if (!window.parent) {
              return;
            }
            // できれば第2引数は適切な origin 指定をすべき
            window.parent.postMessage("disconnected adb", "*");
            return;
          }
        }
      })
      .catch((e) => {
        //時々発生するが問題なし
        console.warn(e);
      })
      .finally(() => {
        setTimeout(() => {
          step(dataConnection, count);
        }, 500);
      });
  };

  useEffect(() => {
    // canvasに〇を描く
    if (!one) {
      setOne(true);
      return;
    }
    connectP2p();
  }, [one]);

  useEffect(() => {
    setTimeout(() => {
      const tmpConnectPeerId: string | null | undefined = queryPeeId;
      console.warn("params", queryPeeId);
      if (tmpConnectPeerId) {
        setConnectPeerId(tmpConnectPeerId);
        makeCall(tmpConnectPeerId);
      }
    }, 1000);
  }, []);

  const connectP2p = () => {
    peer.on("open", () => {
      console.warn("peer open");
      setPeerId(peer.id);
    });
  };

  const makeCall = (peerId: string = "") => {
    const tmpConnectPeerId = peerId ? peerId : connectPeerId;
    console.log("makeCall", tmpConnectPeerId);
    const dataConnection = peer.connect(tmpConnectPeerId);

    dataConnection.once("open", async () => {
      console.warn(`=== DataConnection has been opened ===\n`);
      const data = {
        name: "SkyWay",
        msg: "Hello, World!",
      };
      dataConnection.send(data);
      step(dataConnection, 0);
      setTmpDataConnection(dataConnection);
    });
    dataConnection.once("close", () => {
      console.warn(`=== DataConnection has been closed ===\n`);
    });
    dataConnection.once("close", () => {
      console.warn(`=== DataConnection has been closed ===\n`);
    });
    dataConnection.on("data", async (data: any) => {
      console.warn(`Remote:`, data);
      let remoteData = data;
      if (typeof data === "string") {
        remoteData = JSON.parse(data);
      }
      // clickイベントを実行する
      if (Object.keys(remoteData.type).indexOf("attribute") === -1) {
        console.warn(`click x:${remoteData.type.x}, y:${remoteData.type.y}`);
        clickRequest(remoteData.type.x, remoteData.type.y);
        // clickPositionRequest(remoteData.type.x, remoteData.type.y);
        return;
      }
      if (remoteData.type.attribute === "click") {
        console.warn(`click x:${remoteData.type.x}, y:${remoteData.type.y}`);
        clickRequest(remoteData.type.x, remoteData.type.y);
      } else if (remoteData.type.attribute === "rightClick") {
        console.warn(
          `rightClick x:${remoteData.type.x}, y:${remoteData.type.y}`
        );
        // clickPositionRequest(remoteData.type.x, remoteData.type.y, true);
      } else if (remoteData.type.attribute === "key") {
        console.warn(`pressKey ${remoteData.type.key}`);
        pressKeyRequest(remoteData.type.key);
      } else if (remoteData.type.attribute === "scroll") {
        console.warn(
          `scroll x:${remoteData.type.x}, y:${remoteData.type.y} to_x:${remoteData.type.to_x}, to_y:${remoteData.type.to_y}`
        );
        scrollRequest(
          remoteData.type.x,
          remoteData.type.y,
          remoteData.type.to_x,
          remoteData.type.to_y
        );
      }
    });
  };

  return (
    <div>
      <h1>skywayを接続して、メディア接続を行う</h1>
      <ul>
        <li>〇skywayでpeerを定義する</li>
        <li>〇URLパラメータに含まれるpeerIDあてにp2p-data接続する</li>
        <li>
          〇クリック位置がp2p-dataで送られてきた場合サーバにリクエストする
        </li>
      </ul>
      <ul>
        <li>〇環境変数でリクエスト先ドメインを設定する</li>
        <li>
          〇サーバに毎秒リクエストして、そのbase64画像をimgに入れp2p-data送信する
        </li>
      </ul>
      <p>peerId: {peerId}</p>
      <p>
        接続先peerId:{" "}
        <input
          onChange={(e) => {
            setConnectPeerId(e.target.value);
          }}
          value={connectPeerId}
        />
        <button
          onClick={() => {
            makeCall();
          }}
        >
          接続
        </button>
      </p>
      <img ref={imgRef} />
      <video
        style={{ border: "solid 1px" }}
        ref={remoteVideoRef}
        width="800px"
        height="450px"
        muted={true}
      ></video>
    </div>
  );
};

export default Main;
