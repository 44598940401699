import logo from "./../logo.svg";
import React from "react";

const Home: React.FC = () => {
  console.warn("Hone");
  return (
    <div>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>クリックしたら、イベントが送信される</p>
        <button
          onClick={() => {
            if (!window.parent) {
              return;
            }
            // できれば第2引数は適切な origin 指定をすべき
            window.parent.postMessage("hello", "*");
          }}
        >
          イベント送信(hello)
        </button>
        <button
          onClick={() => {
            if (!window.parent) {
              return;
            }
            // できれば第2引数は適切な origin 指定をすべき
            window.parent.postMessage("timeleap", "*");
          }}
        >
          イベント送信(timeleap)
        </button>
      </header>
    </div>
  );
};

export default Home;
