export const pressKeyRequest = async (key: string) => {
  const url = `${process.env.REACT_APP_SERVER_DOMAIN}/rura/localserver/android/press_key`;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  try {
    const res = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify({ data: { key } }),
    });
    return [await res.status, await res.json()];
  } catch (e) {
    throw await e;
  }
};
