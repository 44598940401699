export const clickRequest = async (x: number, y: number) => {
  const url = `${process.env.REACT_APP_SERVER_DOMAIN}/rura/localserver/android/click`;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  try {
    const res = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify({ data: { x, y } }),
    });
    return [await res.status, await res.json()];
  } catch (e) {
    throw await e;
  }
};

export const scrollRequest = async (
  x: number,
  y: number,
  to_x: number,
  to_y: number
) => {
  const url = `${process.env.REACT_APP_SERVER_DOMAIN}/rura/localserver/android/scroll`;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  try {
    const res = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify({ data: { x, y, to_x, to_y } }),
    });
    return [await res.status, await res.json()];
  } catch (e) {
    throw await e;
  }
};
