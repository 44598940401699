import React, { useEffect, useRef, useState } from "react";
import Peer from "skyway-js";
import { useLocation, useParams } from "react-router-dom";
import { clickRequest } from "../api/clickHook";
import { getScreenRequest } from "../api/screenHook";

const MainMedia: React.FC = () => {
  const [peerId, setPeerId] = useState<string | null>(null);
  const [connectPeerId, setConnectPeerId] = useState<string>("");
  const [one, setOne] = useState<boolean>(false);
  // const [tabletImage, setTabletImage] = useState<{
  //   img: string;
  //   tablet: { width: number; height: number };
  // } | null>(null);
  // const [count, setCount] = useState<number>(100);
  const canvasRef: any = useRef(null);
  const remoteVideoRef: any = useRef(null);
  const peer: any = new Peer({
    key: process.env.REACT_APP_SKYWAY_KEY
      ? process.env.REACT_APP_SKYWAY_KEY
      : "",
  });
  // const search = useLocation().search;
  // const query = new URLSearchParams(search);
  const { queryPeeId } = useParams();
  const getContext = (): CanvasRenderingContext2D => {
    const canvas: any = canvasRef.current;
    return canvas.getContext("2d");
  };
  const randRange = (min: number, max: number) =>
    Math.floor(Math.random() * (max - min + 1) + min);

  // const step = () => {
  //   const ctx: CanvasRenderingContext2D = getContext();
  //   ctx.fillRect(0, 0, 800, 450);
  //   ctx.arc(100, 100, 50, (0 * Math.PI) / 180, (360 * Math.PI) / 180, false);
  //   ctx.fillStyle = "rgba(255,0,255,0.8)";
  //   ctx.fill();
  //   ctx.lineWidth = 8;
  //   ctx.stroke();
  //   ctx.save();
  //   requestAnimationFrame(step);
  // };
  let count = 200;
  let tabletImage: any = null;
  const step = (callback: any) => {
    const ctx: CanvasRenderingContext2D = getContext();
    const img = new Image();
    if (count % 200 === 0) {
      console.warn("step", count, count % 100);
      const randomNo = randRange(1, 6);
      const result = getScreenRequest();
      result
        .then((res) => {
          console.log("getScreenRequest", randomNo, res);
          if (res[0] === 200) {
            img.src = res[1].img;
            const width = Number(res[1].tablet.width);
            const height = Number(res[1].tablet.height);
            // console.warn(res[1]);
            tabletImage = { img: res[1].img, tablet: { width, height } };
            console.log(`tablet width:${width} / height:${height}`);
            img.width = width;
            img.height = height;
            canvasRef.current.width = width;
            canvasRef.current.height = height;
            img.onload = function() {
              ctx.drawImage(img, 0, 0, width, height);
            };
            ctx.save();
          }
        })
        .catch((e) => {
          console.error(e);
        });
    } else if (count % 100 === 0) {
      if (tabletImage) {
        console.log("tabletImage", tabletImage);
        img.src = tabletImage.img;
        const width = Number(tabletImage.tablet.width);
        const height = Number(tabletImage.tablet.height);
        img.width = width;
        img.height = height;
        canvasRef.current.width = width;
        canvasRef.current.height = height;
        img.onload = function() {
          ctx.drawImage(img, 0, 0, width, height);
        };
        ctx.save();
      }
    }
    count += 1;
    requestAnimationFrame(step);
    // }
  };

  useEffect(() => {
    // canvasに〇を描く
    if (!one) {
      setOne(true);
      return;
    }
    console.log("requestAnimationFrame");
    requestAnimationFrame(step);
    connectP2p();
  }, [one]);

  useEffect(() => {
    setTimeout(() => {
      const tmpConnectPeerId: string | null | undefined = queryPeeId;
      console.warn("params", queryPeeId);
      if (tmpConnectPeerId) {
        setConnectPeerId(tmpConnectPeerId);
        makeCall(tmpConnectPeerId);
      }
    }, 1000);
  }, []);

  // useEffect(() => {
  //   getScreenImage();
  // }, []);

  // const getScreenImage = () => {
  //   const result = getScreenRequest();
  //   result
  //     .then((res) => {
  //       console.log("getScreenRequest", res);
  //       if (res[0] === 200) {
  //         console.warn(res[1].img);
  //       }
  //     })
  //     .catch((e) => {
  //       console.error(e);
  //     });
  // };

  const connectP2p = () => {
    peer.on("open", () => {
      console.warn("peer open");
      setPeerId(peer.id);
    });
    initP2p();
  };

  const initP2p = () => {
    // p2p-media接続
    peer.on("call", (mediaConnection: any) => {
      const localStream = canvasRef.captureStream(10);
      mediaConnection.answer(localStream);

      mediaConnection.on("stream", async (stream: any) => {
        if (remoteVideoRef && remoteVideoRef.current) {
          remoteVideoRef.current.srcObject = stream;
          remoteVideoRef.current.muted = true;
          remoteVideoRef.current.play();
        }
      });

      mediaConnection.on("close", () => {
        if (remoteVideoRef && remoteVideoRef.current) {
          remoteVideoRef.current.srcObject = null;
        }
      });
    });
  };

  const makeCall = (peerId: string = "") => {
    const tmpConnectPeerId = peerId ? peerId : connectPeerId;
    console.log("makeCall", tmpConnectPeerId);
    const localStream = canvasRef.current.captureStream(1);
    const mediaConnection = peer.call(tmpConnectPeerId, localStream);
    const dataConnection = peer.connect(tmpConnectPeerId, localStream);
    mediaConnection.on("stream", async (stream: any) => {
      remoteVideoRef.current.srcObject = stream;
      await remoteVideoRef.current.play().catch(console.error);
    });
    mediaConnection.on("close", () => {
      if (remoteVideoRef && remoteVideoRef.current) {
        remoteVideoRef.current.srcObject = null;
      }
    });
    dataConnection.once("open", async () => {
      console.warn(`=== DataConnection has been opened ===\n`);
    });
    dataConnection.once("close", () => {
      console.warn(`=== DataConnection has been closed ===\n`);
    });
    dataConnection.on("data", async (data: any) => {
      console.warn(`Remote:`, data);
      let remoteData = data;
      // clickイベントを実行する
      if (Object.keys(remoteData.type).indexOf("attribute") === -1) {
        console.warn(`click x:${remoteData.type.x}, y:${remoteData.type.y}`);
        clickRequest(remoteData.type.x, remoteData.type.y);
        // clickPositionRequest(remoteData.type.x, remoteData.type.y);
        return;
      }
      if (remoteData.type.attribute === "click") {
        console.warn(`click x:${remoteData.type.x}, y:${remoteData.type.y}`);
        clickRequest(remoteData.type.x, remoteData.type.y);
      } else if (remoteData.type.attribute === "rightClick") {
        console.warn(
          `rightClick x:${remoteData.type.x}, y:${remoteData.type.y}`
        );
        // clickPositionRequest(remoteData.type.x, remoteData.type.y, true);
      } else if (remoteData.type.attribute === "key") {
        console.warn(`pressKey ${remoteData.type.key}`);
        // pressKeyRequest(remoteData.type.key);
      }
    });
  };

  return (
    <div>
      <h1>skywayを接続して、メディア接続を行う</h1>
      <ul>
        <li>〇skywayでpeerを定義する</li>
        <li>〇canvasStreamを取得してp2p-mediaのstreamに入れる</li>
        <li>
          〇URLパラメータに含まれるpeerIDあてにp2p-media, p2p-data接続する
        </li>
        <li>
          〇クリック位置がp2p-dataで送られてきた場合サーバにリクエストする
        </li>
      </ul>
      <ul>
        <li>〇環境変数でリクエスト先ドメインを設定する</li>
        <li>〇サーバに毎秒リクエストして、そのbase64画像をcanvasに入れる</li>
      </ul>
      <p>peerId: {peerId}</p>
      <p>
        接続先peerId:{" "}
        <input
          onChange={(e) => {
            setConnectPeerId(e.target.value);
          }}
          value={connectPeerId}
        />
        <button
          onClick={() => {
            makeCall();
          }}
        >
          接続
        </button>
      </p>
      <canvas ref={canvasRef}></canvas>
      <video
        style={{ border: "solid 1px" }}
        ref={remoteVideoRef}
        width="800px"
        height="450px"
        muted={true}
      ></video>
    </div>
  );
};

export default MainMedia;
