import React from "react";
import Home from "./pages/Home";
import Demo from "./pages/Demo";
import Main from "./pages/Main";
import DemoMedia from "./pages/DemoMedia";
import MainMedia from "./pages/MainMedia";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/demo" element={<Demo />} />
        <Route path="/main/:queryPeeId/:querySkywayKey" element={<Main />} />
        <Route path="/demo_media" element={<DemoMedia />} />
        <Route path="/main_media/:queryPeeId" element={<MainMedia />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
